
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
  },
  setup() {
    const discountAgreement = ref(false)
    const priceAgreement = ref(false)
    const { closeAllModals } = useModals()

    return {
      discountAgreement,
      closeAllModals,
      priceAgreement,
    }
  },
})
